/**
 * Hamburger toggle for the navigation.
 */

const navigation = document.getElementById('site-navigation');
const toggleButton = document.getElementById('site-navigation-button');

const navigationActiveClassName = 'navigation--active';
const toggleButtonActiveClassName = 'hamburger--active';

const showNavigation = () => {
    navigation.classList.add(navigationActiveClassName);
    toggleButton.classList.add(toggleButtonActiveClassName);
    toggleButton.setAttribute('aria-expanded', 'true');
};

const hideNavigation = () => {
    navigation.classList.remove(navigationActiveClassName);
    toggleButton.classList.remove(toggleButtonActiveClassName);
    toggleButton.setAttribute('aria-expanded', 'false');
};

if (navigation && toggleButton) {
    toggleButton.addEventListener('click', e => {
        const isActive = toggleButton.getAttribute('aria-expanded') === 'true';
        isActive ? hideNavigation() : showNavigation();
    });

    document.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
            hideNavigation();
        }
    });
}

/**
 * Opening and closing for menu flyouts.
 */

const menu = document.querySelector('.menu');
const menuItemsWithFlyouts = Array.from(document.querySelectorAll('.menu__item--has-descendants'));
const menuItemActiveClassName = 'menu__item--active';

const closeAllFlyouts = () => {
    menuItemsWithFlyouts.forEach(el => el.classList.remove(menuItemActiveClassName));
};

const toggleFlyout = menuItem => {
    const isActive = menuItem.classList.contains(menuItemActiveClassName);

    // Allow only one open flyout in the desktop navigation
    const isMobileLayout = Boolean(toggleButton.offsetParent);
    if (!isMobileLayout) {
        closeAllFlyouts();
    }

    if (isActive) {
        menuItem.classList.remove(menuItemActiveClassName);
    } else {
        menuItem.classList.add(menuItemActiveClassName);
    }
};

if (menu) {
    menuItemsWithFlyouts.forEach(item => {
        const node = item.querySelector('.menu__node');

        node.addEventListener('click', e => {
            e.preventDefault();
            toggleFlyout(item);
        });

        node.addEventListener('keydown', e => {
            if (!['Enter', ' '].includes(e.key)) return;
            e.preventDefault();
            toggleFlyout(item);
        });
    });

    navigation.addEventListener('keydown', e => {
        if (e.key !== 'Escape') return;
        closeAllFlyouts();
    });

    document.addEventListener('click', e => {
        if (!menu || menu.contains(e.target)) return;
        closeAllFlyouts();
    });
}
