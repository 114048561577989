import PhotoSwipeLightbox from '../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from '../../../node_modules/photoswipe/dist/photoswipe.esm.js';

const language = document.documentElement.getAttribute('lang');
const texts = {
    de: {
        closeTitle: 'Vergrößerungsansicht schließen',
        arrowPrevTitle: 'Zum vorherigen Bild',
        arrowNextTitle: 'Zum nächsten Bild',
        errorMsg: 'Das Bild konnte nicht geladen werden.',
    },
    en: {
        closeTitle: 'Close',
        arrowPrevTitle: 'Previous image',
        arrowNextTitle: 'Next image',
        errorMsg: 'The image cannot be loaded.',
    },
};
const textsForLanguage = texts[language] || texts.de;

const lightboxOptions = {
    bgOpacity: 1,
    showHideAnimationType: 'none',
    preload: [1, 1],
    ...textsForLanguage,
    paddingFn: viewportSize => {
        return {
            top: 80,
            bottom: 80,
            left: viewportSize.x < 1000 ? 0 : 65,
            right: viewportSize.x < 1000 ? 0 : 65,
        };
    },
    pswpModule: PhotoSwipe,
};

const uniqueLightboxGroups = Array.from(
    new Set(
        Array.from(document.querySelectorAll('[data-lightbox]')).map(el => el.dataset.lightbox),
    ),
);

uniqueLightboxGroups.forEach(group => {
    const lightboxLinksInGroup = Array.from(
        document.querySelectorAll(`[data-lightbox="${group}"]`),
    );
    const dataSource = lightboxLinksInGroup.map(lightboxLink => {
        const image = lightboxLink.querySelector('img');
        return {
            src: lightboxLink.href,
            srcset: lightboxLink.dataset.lightboxSrcset,
            width: lightboxLink.dataset.lightboxWidth,
            height: lightboxLink.dataset.lightboxHeight,
            alt: image.alt,
            caption: lightboxLink.dataset.lightboxCaption,
            credits: lightboxLink.dataset.lightboxCredits,
        };
    });
    const options = {
        dataSource,
        ...lightboxOptions,
    };
    const lightbox = new PhotoSwipeLightbox(options);

    lightboxLinksInGroup.forEach(lightboxLink =>
        lightboxLink.addEventListener('click', e => {
            e.preventDefault();
            lightbox.loadAndOpen(lightboxLinksInGroup.findIndex(el => lightboxLink.isSameNode(el)));
        }),
    );

    lightbox.on('uiRegister', function () {
        lightbox.pswp.ui.registerElement({
            name: 'figure-caption',
            order: 9,
            appendTo: 'root',
            onInit: element => {
                element.setAttribute('aria-live', 'polite');

                lightbox.pswp.on('change', () => {
                    const { caption, credits } = lightbox.pswp.currSlide.data;

                    if (!caption && !credits) {
                        element.hidden = true;
                        element.innerHTML = '';
                        return;
                    }

                    const captionHtml = caption
                        ? `<span class="pswp__caption-text">${caption}</span>`
                        : '';
                    const creditsHtml = credits
                        ? `<span class="pswp__credits">${credits}</span>`
                        : '';
                    element.hidden = false;
                    element.innerHTML = `
                        <p class="pswp__caption">
                            ${captionHtml}
                            ${creditsHtml}
                        </p>`;
                });
            },
        });
    });

    // https://github.com/dimsemenov/PhotoSwipe/issues/1966#issuecomment-1305661937
    lightbox.addFilter('useContentPlaceholder', () => false);

    lightbox.init();
});
